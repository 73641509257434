<template>
  <div class="text-center vh-100">
    <b-card
      class="exphyslab-card"
      bg-variant="project"
    >
      <b-card-title>Thanks for visiting!</b-card-title>
      <b-row>
        <b-col md="6" class="d-flex text-right justify-content-center align-self-center">
          <b-img :src="require(`@/assets/logo.svg`)" alt="" class="logo"></b-img>
        </b-col>
        <b-col md="6" class="text-right justify-content-center align-self-center">
          We have moved to a new platform: ExPhysLab.
          <br><br>
          <b-button class="btn-pkg" href="https://exphyslab.com/" target="_blank">
            <BIconBoxArrowUpRight />
            Access the <code>ExPhysLab</code> website.
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BButton,
  BIconBoxArrowUpRight,
} from 'bootstrap-vue';

export default {
  name: 'Home',
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    BIconBoxArrowUpRight,
  },
};
</script>

<style scoped>
.text-center {
  align-items: center;
  justify-content: center;
  display: flex;
}
.exphyslab-card {
  width: 50%;
  padding: 2rem;
}
.logo {
  width: 50%;
}
</style>
